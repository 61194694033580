const utils = {
  getDryPowerImgBankUrlAPI() {
    return process.env.VUE_APP_DP_BANK_IMG_URL;
  },

  getReceiptByReceiptNumberAPI() {
    return process.env.VUE_APP_GET_RECEIPT;
  },

  getDriverByLicense() {
    return process.env.VUE_APP_GET_DRIVER_BY_LICENSE;
  },

  postGenerateReceipt() {
    return process.env.VUE_APP_POST_NEW_RECEIPT;
  },

  getYardItemsByScacCode() {
    return process.env.VUE_APP_GET_YARD_ITEMS_BY_SCAC_CODE;
  },

  getValueApiManagement() {
    return process.env.VUE_APP_APIMANAGEMENT_HEADER;
  },
};

export default utils;
