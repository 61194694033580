import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "CheckInCheckOutCarrier" },
  },
  {
    path: "/checkinout/:receiptid?",
    name: "CheckInCheckOutCarrier",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "checkinout" */ "../views/CheckInCheckOutCarrierView.vue"
      ),
  },
  {
    path: "/thankyou",
    name: "ThankYou",
    component: () =>
      import(/* webpackChunkName: "thankyou" */ "../views/ThankYouView.vue"),
  },
  {
    path: "/receipt/:receipt_id",
    name: "NewReceipt",
    component: () => import("../views/NewReceiptView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
