<template>
  <v-app-bar app color="#FAFAFA" elevation="2" class="px-lg-16">
    <div class="appbar-dp d-flex justify-space-between">
      <v-img
        alt="DrayPower"
        class="shrink mt-1"
        contain
        min-width="100"
        :src="
          getDryPowerImgBankUrlAPI() +
          'draypowerimages/app-bar-images/draypowerYMS.svg'
        "
        width="173"
      />

      <v-spacer></v-spacer>
      <div class="ml-3 d-none d-lg-block">
        <v-btn
          v-for="(dp_link, i) in dp_links"
          :key="i"
          @click="redirectTo(dp_link.href)"
          :class="{ 'yms_orange--text': dp_link.active }"
          class="my-2 text-capitalize"
          rounded
          text
        >
          {{ dp_link.text }}
        </v-btn>
      </div>
      <v-avatar size="48">
        <v-img
          src="https://staccimagesfronttest.blob.core.windows.net/draypowerimages/app-bar-images/supra_logo.png"
          contain
          alt="John"
        />
      </v-avatar>
    </div>
  </v-app-bar>
</template>

<script>
import utils from "../../../utils";
import { eventBus } from "../../../main";

export default {
  name: "AppNavBar",
  data: () => ({
    user: {
      initials: "SA",
      fullName: "",
      email: "",
    },
    dp_links: [],
  }),
  methods: {
    getDryPowerImgBankUrlAPI() {
      return utils.getDryPowerImgBankUrlAPI();
    },

    showSnackBar(success, message, timeout) {
      eventBus.$emit("showSnackBar", success, message, timeout);
    },
  },
  mounted() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.appbar-dp {
  width: 100%;
}
</style>
