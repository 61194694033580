<template>
  <v-app>
    <SnackBarService />
    <AppNavBar />
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppNavBar from "./components/Header/AppNavBar/AppNavBar.vue";
import SnackBarService from "./components/Shared/SnackBarService.vue";

export default {
  name: "App",
  components: {
    AppNavBar,
    SnackBarService,
  },
  data: () => ({
    items: [{ title: "Lakme yard" }],
  }),
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
